@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Playfair+Display:wght@400..900&display=swap');

body {
  font-family: 'Playfair Display', sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Dancing Script', cursive;
  font-size: 80px;
  font-weight: 500;
}

h2, h3, h4, h5, h6, p, span, a, button {
  font-family: 'Playfair Display', sans-serif;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

h1, h2, h3, h4, h5, h6, p, span {
  color: #2D2D2D
}

button {
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

* {
  box-sizing: border-box;
  margin: 0;
}